
#site-footer { 
	padding:1rem 0rem !important;
	background:$footer_bg !important;
	
	.version {
		color:darken(white,5%); 
		font-size:0.8em;
		@include md {
			text-align:right;	
		}
		

	}
	.list div {
		color:white;
	}
	p {
		opacity:.9;
		margin-bottom: 0.8em;
	}
	a:hover:not(.button) {
		color: #C5E5FF;
	}
	.meta {
		> * {
			margin-right:10px;
		}
	}
	.fb {
		&-0 {
			fill: #FFF;
		}
		&-1 {
			fill: transparent;
		}
	}
	.logo {
		max-width: 10rem;
		width: auto;
	}
	
	@include sm {
		&,
		.list div { 
			font-size:.9rem;
		}
		
		.column {
			padding-top:0.5rem !important;
			padding-bottom:0rem !important;
			&:last-child {
				padding-bottom:0.5rem !important;
				
			}
		}
		img {
			max-width: 7rem;
		}
		
	}
	

}
.privacy, .terms {
	padding-bottom: 2em;
	ol {
		margin-left: 2em;
		& li {
			margin-bottom: .25em;
		}
	}
	ul {
		margin: .25em 0 .25em 2em;
		list-style-type: disc;
	}
	.list-alpha{
		list-style-type: lower-alpha;
	}
	.list-i {
		list-style-type: lower-roman;
	}
}

.address-search {
	padding:0 !important;
	flex-grow:1;
	margin-bottom:0 !important;
	input {
		height:52px !important;
	}
}

.dva,
.medicare-card,
.address-search-full {
	margin-bottom: 1rem;
	.grid label {
		color:#7b7b7b !important;
	}
}

#user_signed_area {
	display: flex;
	flex-direction: row;
	align-items: flex-start;

	@media only screen and (min-width: 767px) {
		.ui.grid {
			> .row:not(:first-child) {
				margin-top: 1rem;
				padding: 1rem 0;
			}
		}
	}

	> aside {
		flex-grow:1;
		> footer {
			margin-top:20px;
			> .button {
				margin-right:10px;
			}
		}
	}
	
}

img.react-gravatar {
	border-radius:3px;
}

.action-btns {
	display:flex;
	justify-content: space-around;
	flex-wrap:wrap;
	margin:10px -10px 0;
	>* {
		flex:1 1 100% !important;
		display:flex;
		overflow: hidden;
		margin-left:10px !important;
		margin-right:10px !important;
		margin-bottom:20px !important;
		@media screen and (min-width:768px) {
			flex:1 1 calc(50% - 40px) !important;
			max-width:(960px / 2);
		}
	}
	@media screen and (max-width:767px) {
		flex-direction:column;
	}
	
}

.flex-vertical {
	display:flex !important;
	flex-direction:column;
	flex-grow:1;
}

.flex-horizontal {
	display:flex !important;
	flex-direction:row;
	align-items: stretch;
	flex-grow:1;
}


.med-details {
	margin-top:0 !important;
	
	.item {
		margin-left: 0 !important;
		margin-right: 1em !important;
		padding-left:0 !important;
		padding-right:1rem !important;
	}
	b {
		text-transform: capitalize;
	}
	span {
		opacity:1
	}
}

.form-area {
	margin-top:1em;
}

.ui.modal.med-confirm {
	> .header {
		display:flex;
		flex-direction:column;
		margin-right: 1rem;
		align-items: flex-start;
		@media screen and (min-width:500px) {
			flex-direction:row;
			align-items: center;
		}
		> span {
			flex-grow:1;
		}
		> .actions {
			background:transparent;
			> .button {
				margin-bottom:0;
				&:first-child {
					margin-left:0;
				}
				&:last-child {
					margin-right:0;
				}
			}
		}
	}
}




.login-page {
	background-color:#F6F6F7;
	@include lg {
		background:#F6F6F7 url('/www/images/homepage/return-banner-lg.jpg') 0 0 no-repeat;
		background-size:cover;
		background-position: top 30px right -150px;
		background-attachment: fixed;
		padding-bottom:2rem;
		grid-template-columns: 2fr 1fr;
	}
	@include xl {
		grid-template-columns: 1fr 1fr;
	}
	@media (min-width: 1024px) { 
		background-position: top 30px right -100px;
	}
	
	flex:1;
	display:grid;
	grid-template-columns: 1fr;
	margin-bottom: -2em;
	padding:2rem 0 4rem;

	body.ionic & {
		padding-bottom: 4rem;
	}
	place-items: center;
	
	> aside {
		grid-column-start: 1;
		margin:0 1rem;
		min-height:400px;
		position:relative;
		@include md {
			width:400px;
		}
		p, h3 {
			text-align: center;
		}
	}
	&.signup {
		> aside {
			margin-top: 1rem;
			@include md {
				width:570px;
			}
		}
	}
	
	.ui.form {
		.login-email {
			input {
				@apply rounded-b-none text-base;
			}
		}
		.login-password {
			input {
				@apply rounded-t-none border-t-0 text-base;

			}
		}
		
	}
	.social {
		display: grid;
		grid-template-columns: 1fr 6fr 1fr;
		grid-column-gap: 0px;
		justify-items: center;
		svg {
			width:24px;
			height:24px;
		}
		>*:nth-child(2) { grid-column-start: 2; }
		>*:nth-child(1) { grid-column-start: 1;}
	}

	&.icann {
		@include lg {
			background:#F6F6F7 url('https://storage.googleapis.com/instant-med-public/icann/green-doc-bg.jpg') 0 0 no-repeat;
			background-size:cover;
			background-position: center;
			background-attachment: fixed;
		}
	}
}

	
.ui.popup.popup-login {
	max-width: 450px;
	padding: 0;
	margin-top: 0;
	max-height: 90vh;
	overflow-y: scroll!important;
    scrollbar-width: none;
	margin-top: .5rem;
	border: none;

	@include hideScroll;

	&::before {
		display: none;
	}
	.ui.grid > .row:has(.column:empty) {
		height: auto;
		padding: 0;
	}
	.user_area {
		padding-top: 0;
		h5 {
			margin-top: 0;
		}
	}
	.row {
		padding: 0.15rem 0;
		> .column {
			@include sm {
				margin-top: 0;
				margin-bottom: 0;
			}
		}
	}
	.message-bar {
		margin: 0;
		> div {
			border: none;
			margin-bottom: 0;
		}
		p {
			// width: 90%;
			font-size: 0.9rem;
		}
		h3 {
			font-size: 1rem;
		}
	}
	.ui.pointing.label {
		margin: .25rem 0 0;
	}
	> .login-page {
		background-image: none;
		background-color: #f7f7f7;
		padding: 0;
		margin: 0!important;
		@include sm {
			padding: 0 0 5rem;
		}
		> aside {
			margin: 0!important;
			width: 450px;
			form {
				padding-top: 0;
				border-radius: 0.5rem;
				> .ui.grid:last-child{
					margin-bottom: 0;
				}
				a:last-of-type {
					margin-top: 1.5rem;
				}
				@include sm {
					background-color: #fff;
					padding-bottom: 0!important;
				}
			}
			@include sm {
				width: calc(100vw - 3rem);
				form {
					padding-bottom: 2rem;
				}
			}
			h3 {
				display: none;
			}
		}

		.ui.grid > .row {
			h5 {
				margin-bottom: .5rem;
				margin-top: 0.5rem;
				font-size: 1.1rem;
			}
			&:not(:has(.ui.grid)) {
				h5 {
					margin-bottom:0;
				}
			}
			&> .column:empty {
				display: none;
			}
		}
	}
	@include sm {
		padding: 1.5rem;
		right: 0;
		left: 0;
		border-radius: 0;
		max-height: 100vh;
		border-top: none;
		background-color: #f7f7f7;
	}
}
/*.bg-fixed {
	overflow: hidden!important;
}*/
#popup-login-cont {
	@include sm {
		position: fixed!important;
		max-width: 100%;
		top: 60px!important;
	    max-height: 150vh!important;
		height: 150vh;
		transform: none!important;
	}
	@include lg {
		// filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.25)) drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25));
		box-shadow: none;
	}
	.ui.centered.inline.loader.active {
		margin-top: 2rem;
	}
}

.user_area, .profile {
	.ui.grid > .row {
		padding: .5rem 0;
		.ui.grid {
			margin-top: -.5rem;
		}
	}
	@include sm {
		.ui.stackable.grid {
			> .row > .column {
				padding: .5rem 0 !important;
			}
		}
		.ui.grid {
			> .row > .column {
				padding-top: .5rem!important;
				padding-bottom: .5rem!important;
			}
		}
	}
}


.user_area {
	.spaced {
		display:flex;
		flex-direction:row;
		justify-content: space-between;
	}
	.ui.basic.button.link-button {
		body & {
			border:none !important;
			box-shadow: none !important;
			text-decoration: underline;
			> i {
				text-decoration: none;
			}
		}
	}
	.user.secret {
		margin-left:5px;
	}
}

.select-option {
	width:100%;
	min-width:200px !important;
	padding-top:.5em !important;
	padding-bottom:.5em !important;
	padding-left:.5em !important;
	h5 {
		line-height:1.1em;
		width:100%;
		white-space: nowrap;
		overflow:hidden;
		text-overflow: ellipsis;
		max-width:200px;

	}
	div.item {
		border-bottom:1px solid rgba(128,128,128,.2) !important;
	}
	
	.menu {
		max-height: 30rem !important;
	}
}

.add-user {
	input,
	textarea {
		padding: (0.67857143em* 1.2) (1em * 1.2)  !important;
		font-size: (1em * 1.2) !important;
	}
	.ui.checkbox {
		padding-left:0;
		label {
			font-size:1.1em;
			padding-left: (1.85714em * 1.2);
			&:before {
				width:20px;
				height:20px;
			}
		}
	}
}

body .support-actions {
	position:fixed;
	bottom:20px;
	right:20px;
	left:20px;
	z-index:999; // below modals
	display: flex;
    pointer-events: none;
	>div {
		display:inline-block;
		margin-left:auto;
		position:relative;
	}
	@include sm {
		bottom:10px;
		right:10px;
	}
	@supports (position: sticky) { 
		position:sticky;
		// margin-left:auto;
		//bottom:1.5rem;
		right:1.5rem;
		left:1.5rem;
		// z-index:100000;
		@include sm {
			//bottom:1rem;
			right:1rem;
		}
		>div {
			position: absolute;
			left:0;
			bottom:.5rem;
			@include sm {
				bottom:1rem;
			}
		}
	}
	
	
	button.ui.button {
		pointer-events: all;
		
		&.left {
			margin-left:0.25em;
		}
	}


}
.support-button {
	border-radius: 40px !important;
	padding:0.58928571em (0.58928571em * 2) 0.58928571em !important;
}

.home-logo {
	@media screen and (min-width:768px) {
		height:80px;
	}
	height:60px;
	margin-bottom:20px;
}

.ui.modal > .content.scrolling {
	-webkit-overflow-scrolling: touch;	
} 
.ui.modal > .content .popup-limit  {
	max-width:100% !important;
}

.signature-wrap {
	background-color:#fafafa !important;
	&.sign-here {
		position: relative;
		&:after {
			pointer-events: none;
			font-size: 1rem;
			content: 'Sign Here';
			position: absolute;
			padding: 0 1rem;
			bottom: 0.5rem;
			left: 1rem;
			right: 1rem;
			border-top: 2px solid #000;
		}
	}
	&.standout {
		border: 1px solid #000;
	}
}



.ui.breadcrumb.catalog {
	@include sm {
		font-size:0.92857143rem !important;
		line-height:1.25rem;
	}
}
.info-search .input {
	&>input[type="text"] {
		font-size: 1.5rem;
		line-height: 2rem;
		height: 3.5rem!important;
	}
	.search-icon {
		padding: 0.125rem;
		top: 1.025rem;
		left: 0.875rem;
	}
}

.cat-search, .info-search {
	display:flex;
	flex-direction:column;
	.input {
		width: 100%;
		margin-top: 1rem;
	}
	.search-icon {
		position: absolute;
		padding: 0.125rem;
		top: 0.933rem;
		left: 0.875rem;
	}
	.ui[class*="left icon"].input>input {
		padding-left: 2.75rem !important;
		height: 54px;
		padding: 0.938rem 0.875rem;
	}
	.input>input[type="text"] {
		border-radius: 32px;
		border: none;
		box-shadow: inset 0px 0px 0px 1px #BEBEBE;
		&::placeholder { color: #272727; }
		&:focus {
			box-shadow: inset 0px 0px 0px 2px #BEBEBE;
			&::placeholder { color: #272727;}
		}
	}
	.medsearch-test {
		.ui[class*="left icon"].input>input {
			padding: 15px 24px 16px 24px !important;
			height: 52px;
			border: 1px solid #494E53;
			box-shadow: none;
			color: #696D70;
			@include md {
				height: 57px;
				font-size: 1.125rem;
			}
		}
		
		.search-icon {
			right: 1rem;
			top:15px;
			left: auto;
		}
	}
	@include md {
		h1 {
			flex:1 0 auto;
		}
	}
	h1 {
		margin-top:0em !important;
		color: rgba(0, 0, 0, 0.87)!important;
	}

	> div.head {
		display:flex;
		align-items:center;
		justify-content:flex-start;
		flex-direction:row;
		@include xs {
			flex-direction:column;
		}
	}

}

.cards-list {
	@apply my-4 md:my-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-2;
	@include sm {
		margin-top:0;
	}
	.card {
		@apply flex flex-col items-stretch bg-white overflow-hidden md:mb-0 shadow-md hover:shadow-xl transition transform hover:-translate-y-1 rounded-md md:w-full;
		
		@include md {
			min-height:150px;
		}	
		@include sm {
			@apply mb-2 mt-2;
			&.c {
				margin: 0 -1em;
				@apply first:border-t border-b hover:bg-gray-50 rounded-none shadow-none hover:-translate-y-0
			}
		}

		h5 {
			text-transform: capitalize;
			margin: 0;
			font-size: 1.25rem;
		}
		.ui.button {
			// margin-top: 1.5rem;
			font-weight: 700;
			font-size: 0.875rem;
		}
		.tile-cost > span {
			font-size: 0.75rem;
			line-height: 1rem;
		}
		.tile-info {
			@media screen and (max-width: 600px), (min-width: 1022px) and (max-width: 1200px) {
				bottom: 5rem;
			}
		}
	}
	.order-btn {
		width: 70%;
		margin-top: 0.2em;
		display: block;
		@include sm {
			display: none;
		}
	}
	
}

.login-banner {

	@media only screen and (max-width: 768px) {
		.ui.button {
			padding: 0.5625rem 1rem;
		}
	}
}

@keyframes backgroundIdle
{
  0%   {background: #E03997;}
  25%  {background: #F2711C;}
  50%  {background: #21BA45;}
  75%  {background: #2185D0;}
  100% {background: #E03997;}
}
body.idle {
	> .ui.dimmer {
		background-color:#E03997;
		 //animation: backgroundIdle 30s; 
		 height:100vh;
		 width:100vw;
		 z-index:5000;
		 position: fixed;
	}
	
	button.button.ui {
		&.sound-control {
			position:absolute;
			top:0;
			right:0;
			box-shadow: none !important;
			margin-right:0;
		}
		&.play-button {
			color:#E03997 !important;
			box-shadow: 0px 0px 0px 1px white inset;
			background:white !important;
			font-weight:600;
			font-size:24px;
		}
		&.start-button {
			background-color:#21BA45 !important;
			box-shadow: 0px 0px 0px 1px transparent inset;
			color:white !important;
			font-weight:600;
			font-size:38px;
			margin-top:30px
		}
	}
	

}

table.inverted a { 
	color:white;
	text-decoration:underline;
}


.list-pagination {
	> span {
		display:block;
		@media screen and (min-width:768px) {
			display:inline-block;
			width:30px;
		}
	}	

	
}

input {
	-webkit-transform: translateZ(0px);
}

.orgs-wrap {
		position:absolute;
		top:0;
		height:100%;
		left:0;
		right:0;
		overflow:scroll;
		padding:14px;
}

.rc-tree.orgs-tree {
	font-size:1.2em;
	line-height:1.4em;
	.rc-tree-node-content-wrapper {
		height:auto !important;
	}
	.rc-tree-node-selected {
		font-weight: bold;
		background-color:rgba(#21BA45,.4);
		border:none;
		
	}
	li.filter-node > .rc-tree-node-content-wrapper {
		color:#21BA45 !important
	}
}

//
// Burger menu custom styles
//

.bm-menu-wrap {
	max-width:80vw;
	box-shadow:-1px 0px 2px 1px rgba(0, 0, 0, .2);
	z-index:100010 !important;
}

.bm-overlay {
	z-index:100010 !important;
}

// Mega Menu - Sidebar
.iscr  {
	.side-menu .list {
		a {
			line-height: 1.5rem;
			margin-bottom: 8px;
			padding: 0 1rem;
			&:hover {
				color: $isBrandColor!important;
				@include hover_transition;
			}
		}
	}	

	.list:has(.remove-link-padding) {
		a {
			padding: 0;
		}
	}

	.side-btn {
		font-size: 1.25rem;
		line-height: 1.5rem;
		&:hover {
			svg {
				@include hover_transition;
			}
		}
	}
	.bm-item {
		background-color: #fff!important;
		&-list {
			&:not(.info-link) a:not(.primary) {
				&:hover {
					color: #1B1C1D;
				}
			}
			a {
				span {
				  font-weight: 500;
				}
				&.primary:hover {
					background-color: #1377F7;
				}
			}
		}
	}

	.bm-cross-button {
		height: 1rem;
		width: 1rem;
		margin: 1rem;
		background: #fff;
		width: 20%!important;
		top: 8px!important;
		right: 0!important;
		&::before {
			content: "Close";
			position: absolute;
			right: 1.8rem;
			font-weight: 600;
		}
		> span {
			top: 5px!important;
			right: 1rem!important;
		}
		.bm-cross {
			background: #2D3748;
			width: 1.5px!important;
			margin-right: .5rem;
			height: 1rem!important;
		}
		&:hover {
			color: $isBrandColor;
			cursor: pointer;
			.bm-cross {
				background: $isBrandColor;
			}
		}
	}
	.bm-menu-wrap {
		background-color: #fff;
	}
	.bm-menu {
		background: #fff;
		margin-top: 72px;
		@include lg {
			@include hideScroll;
		}
		
		.panel {
			height: 100vh;
			overflow-y: scroll;
			padding-bottom: 20vh;
		}
		.list .info-link {
			padding-right: 0;
			margin: 0;
			&::after {
				height: 1.5rem;
			}
		}
		.quiz a {
			line-height: 1.875rem;
		}
	    .ui.vertical.menu .menu .item > .menu .item {
			padding: .5rem;
			&:hover {
				color: $isBrandColor!important;
			}
		}
		.ui.inverted.menu {
			background-color: #fff;
			.item {
				color:$isDark!important;
				font-size: 1.25rem;
				font-weight: 600;
				
				&.main {
					display: flex;
					line-height: 1.5rem;
					padding: 1rem;
					&:hover {
						background-color: #E2F3FF;
						svg {
							color: $isBrandColor;
						}
					}
					> .menu > .item.main {
						padding: .7rem;
					}
					&.active {
						color:$isBrandColor!important;
						pointer-events: none;
						> .icon {
							display: none;
						}
					}
				}
			}
		} 
		.ui.vertical.inverted.menu  {
			margin: 0.5em 0;
			.header {
				color:$isDark!important;
				font-size: 1.25rem;
				font-weight: 600;
				margin-bottom: .5em;
			}
			&:not(>.menu) .divider {
				margin-top: 0;
			}
		}

		.divider {
			height:1px;
			background:#E6E6E6;
			opacity: 1;
		}
		
	}
	#popup-login-cont {
		@media (max-width: 380px) {
			top: 80px!important;
		}
	}
}

.iscr2 {
	.bm-cross-button {
		display: none !important;
	}
	.bm-item {
		background-color: #0065F2 !important;
	}
}

.bm-menu {
	.side-logo {
		margin:18px 0 0 1.14285714em;
		height:auto;
		width:50%;
	}
	.side-menu {
		// padding-top:20px !important;
		flex-grow:1;
		a {
			margin-left: 0;
		}
	}
	
	.side-meta {
		padding-bottom:10px;
		
		> .item:before {
			display:none;
		}
	}
	

	.ui.vertical.menu .item{
		font-weight:bold;
		&::before {
			content:none;
		}	
	}
	.ui.vertical.menu .menu .item {
		font-size: 0.9rem;
		> .menu {
			margin-left:0;
			
		}
	}
	.divider {
		height:1px;
		background:#fff;
		opacity:.3;
		margin:15px 0;
	}
	
	.ui.vertical.inverted.menu .header {
		color:rgba(255, 255, 255, 0.9) !important;
		font-size: 1rem;
	}
	
}

.bm-morph-shape {
  fill: #1B1C1D;
}

.bm-menu {
  background: $menu_bg;

  .ui.inverted.menu {
	background: $menu_bg;
  }
}

.bm-item-list a {
  padding: 0.8em;

  span {
    font-weight: 700;
  }
}

.bm-item:focus {
  outline: none;
}

.org-selector { 
	// max-width:200px;
	display:flex;
	.org-button {
		width:300px;
		overflow: hidden;
		text-overflow: ellipsis;
		// direction: rtl;
  		text-align: left;
	}
}

.player-wrapper {
  position: relative;
  .hidden {
	  display:none !important;
  }
  .play-button {
	  position:absolute;
	  bottom:20%;
	  left:50%;
	  transform: translateX(-50%) scale(1.5,1.5);
	  border:1px solid #E03997;
	  margin:0 !important;
  }
  .video-react-poster {
	  background-color:white !important;
  }
}

.ui.items.history-list {
	> .item {
		display:flex;
		flex-direction: row;
		@media screen and (max-width:767px) {
			flex-direction:column;
		}
		padding: 1em 0em !important;
		> .description {
			flex:3 0 75% !important;
			@media (min-width: 1100px) {
				flex: 3 0 65% !important; 
			}
			padding: 0 1rem;
			.size {
				font-size:.8em;
				opacity:.8;
				display:inline-block;
				margin-left:5px;
			}
		}
		.actions { 
			flex:1 0 25% !important;
			flex-direction:column !important;
			justify-content: space-between !important;
			@media (min-width: 1100px) {
				flex: 1 0 35% !important; 
				flex-direction: row !important; 
			}
			@media screen and (max-width:767px) {
				padding-top:20px;
			}
			> .button {
				display: block;
				width:100%;
				margin-bottom:10px;
			}
		}
		.header {
			
		}
		.dates {
			// text-transform: uppercase;
		}
	}
}

.payment-form {
	
	.card {
		display:flex;
		margin-bottom:10px;
		align-items: flex-start;
		> :first-child {
			flex-grow:1;
		}
	}
}

.discount-field {
	display:flex !important;
	flex-wrap:nowrap !important;
	align-items:stretch !important;
	@media screen and (max-width:767px) {
		button {
			margin-bottom:17.5px !important;
		}

	}
}

.meds .head {
	display:flex;
	flex-direction:row;
	align-items:center;

	@include xs {
		flex-direction:column;
	// align-items:flex-start;

	}
		@include sm {
			margin-top:calc(1rem - 0.14285714em);
		}
}

@media only screen and (max-width: 767px) {
	.meds .ui.stackable.grid {
		> .row > .column{
			padding-left:0 !important;
			padding-right:0 !important;

		}
	}
}

.drop-area {
	height:100%;
	display:flex;
	flex-direction:column;
	justify-content: center;
	
	&.drop {
		background:#21BA45;
		position: relative;
		&:after {
			content:"Drop File Here";
			position: absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			display:flex;
			justify-content: center;
			align-items: center;
			color:white;
		}
		* {
			visibility:hidden;
		}
	}
}

.vcall_wrap {
	box-shadow:0 0 3px 1px rgba(0,0,0,.1);
	video {
		display:block;
		
	}
	position:relative;
	border:1px solid #ccc;
	background-color:#ccc;
	div.bg {
		z-index:0;
	}
	.abs_center {
		position: absolute;
		top:50%;
		left:50%;
		transform:translate(-50%,-50%);
		z-index:1;
	}
	.hangup {
		position: absolute;
		right:20px;
		bottom:20px;
		z-index:1;
	}
	> div.bg {
		
	}
	> video {
		position: relative;
		z-index:1;
		// background:rgba(0,0,255,0.5);
	}
	> div.vcall_your {
		box-shadow:0 0 3px 1px rgba(0,0,0,.1);
		
		position: absolute;
		top:0;
		right:0;
		background:#bbb;
		width:25%;
		z-index:10;
		> div.bg {
			
		}
		>video {
			
		}
	}
}

.troubleshoot {
	position: fixed;
	z-index: 1000;
    top: 50%;
    left: 50%;
	width:70%;
    transform: translate(-50%,-50%);
    background: white;
    font-size: 1.5em;
    line-height: 1.5em;
    box-shadow: 0 0 3px rgba(0,0,0,0.3);
    padding: 1em;
    text-align: center;
	display:flex;
	i {
		margin-left: auto;
	}
}



.med-consult.ui.modal.transition {
	border-radius:1.75rem;
	max-width: 750px;
	.image.scrolling.content {
		border-radius: 0 0 1.75rem 1.75rem;
		padding: 0;
	}
	.header {
		padding-top: 32px;
	}
	@media screen and (min-width:768px) {
		.header {
			font-size: 1.5rem;
			line-height: 2rem;
		}
	}
	@media screen and (max-width:767px) {
		border-radius:0;
		max-width: 100%;
		width: 100%;
		height: calc(100vh - 74px);
		margin: 74px 0 0!important;
	}
}
@media screen and (max-width:767px) {
	.prescription.dimmable .ui.page.dimmer {
		background-color: transparent!important;
		padding: 0;
	}
}

.popup_contact {
	@media screen and (min-width:768px) {
		width:600px !important;
		.field {
			display:flex;
			flex-direction:row;
			> :first-child {
				flex:1 1 25% !important;
				display:flex !important;
				align-items: center;
			}
			> :last-child {
				flex:3 1 75% !important;
				
			}
		}
	}
}


.iframe-modal {
	background:rgba(0,0,0,.7);
	height:100vh;
	width:100vw;
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	position: fixed;
	top:0;
	left:0;
	z-index: 100001;
	padding:1em;
	
	&:after {
		content:"Loading…";
		position: absolute;
		top:50%;
		left:50%;
		transform: translate(-50%,-50%);
		background:white;
		padding:1em;
		border-radius: 10px;
		box-shadow:2px 2px 3px 3px rgba(0,0,0,.1);
	}
	&.loaded {
		&:after {
			content: none;
		}
	}
	>div {
		// height:100vh;
		// display:flex;
		font-weight: bold;
		background:white;
		width:100%;
		padding:.5em 1em;
		display:flex;
		align-items: center;
		box-shadow:0 2px 3px 3px rgba(0,0,0,.1);
		> span {
			flex-grow: 1;
		}
	}
	iframe {
		opacity:.9;
		height:calc(100vh - 2em);
		background:white;
		border:none;
	}
}

@media only screen and (max-width: 991px) {
	body .ui.modal > .header {
		padding-right:3.5rem !important;
	}
}

.script-buttons {
	display:flex;
	flex-direction:column;
	> .ui.button {
		margin:.5em 0;
	}
}

.feature-highlight {
	&,&:before {
		background-color: #21BA45 !important;
	}
}

.full-width-input {
	input {
		width: 100%;
	}
}
.message ~ .onboarding {
	margin-top:1em; // shift onboarding a bit if opening hours are displayed
}
.onboarding {
	padding-bottom:1rem;
	margin-bottom:1rem;
	>  div {
		border:2px solid #21BA45 !important;
		box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .10), 0 10px 10px -5px rgba(0, 0,0, .04) !important;
		> .x {
			float:right;
		}
		> .header {
			margin-top:0;
		}
	}
	.list {
		> .item {
			padding:1rem 0;
			@include sm {
				display:flex;
				flex-direction: column;
				.content {
					margin-top:-1em;
					padding-left:0 !important;
				}
				.header {
					margin-left:1.5em;
					margin-bottom: .5em;
				}
				.button {
					font-size:0.85714286rem;
				}
			}
		}
	}
}

.q-buttons {
	display:flex;
	flex-direction:row;
	flex-wrap: wrap;
	> div {
		display:flex;
		min-width:25%;
		margin-right:.5em;
		margin-top:.5em;
		display:flex;
		@include sm {
			flex:1 0 auto;
		}
		> .button {
			flex:1 0 auto;
			&.ui.grey.basic {
				color:rgba(0, 0, 0, 0.87) !important;
			}
			&.ui.red.basic {
				box-shadow: 0px 0px 0px 1px #9F3A38 inset !important;
			}
			
		}


	}
}

.iscr2 .questionnaire pre {
	color: var(--text-muted);
}

.iscr2 .q-buttons > div {
	flex: 1;
}
.iscr2 .a-5 .q-buttons > div {
	flex: auto;
}

.a-5 .q-buttons > div {
		min-width:19%;
}

$arrowSize: 5px;
$arrowOn: rgb(24, 24, 24);
$arrowOff: rgb(175, 175, 175);

.header_top {
	text-align: center;
	vertical-align: top !important;
}

.header_search {
	margin: 10px 0 0 0;
}

.header_arrow {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.arrow_holder {
	display: flex;
	flex-direction: row;
	padding: 6px 0 0 5px;
}

.arrow {
	width: 0;
	height: 0;
	border-left: $arrowSize solid transparent;
    border-right: $arrowSize solid transparent;
}

.up_arrow.arrow_on {
	border-bottom: $arrowSize * 2 solid $arrowOn;
}

.up_arrow.arrow_off {
	border-bottom: $arrowSize * 2 solid $arrowOff;
}

.down_arrow.arrow_on {
	border-top: $arrowSize * 2 solid $arrowOn;
}

.down_arrow.arrow_off {
	border-top: $arrowSize * 2 solid $arrowOff;
}

.arrow_txt {
	font-size: 60%;
	padding: 4px 0 0 5px;
	min-width: 10px;
}

.video-container-43 {
    padding-top:75%;
    height: 0px;
    position: relative;
	>section, 
	> video {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		object-fit: cover;
	}
	> section {
		overflow:hidden;
	}
}

#subscriber {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

#publisher {
    position: absolute;
    width: (360px / 2);
    height: (240px / 2);
    bottom: 10px;
    left: 10px;
    z-index: 100;
    border: 3px solid rgba(255,255,255,.4);
    border-radius: 3px;
}

.ui.container.mfa {
	margin-left: 0px !important;
	margin-right: 0px !important;
}

.suspense-style {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding-top: 30vh;
}

.approve-wr, .live-rows {
	tr.mine {
		@apply bg-green-100;
	}
	tr.pending,
	tr.owned {
		> td {
			pointer-events:none;
		}
	}
	tr.pending{ 
		> td {
			position:relative;
			&:after {
				content:"";
				@apply absolute inset-0 bg-yellow-200 opacity-75 z-10 justify-center items-center flex pointer-events-none;
			}
		}
	}
	tr.admin {
		> td {
			pointer-events: all !important;
		}
	}
}

.landing-pages {
	details {
		summary::-webkit-details-marker {
			display: none;
		}
		summary{
			img {
				transform: rotate(90deg);
			}
		}
		&[open]{
			> summary {
				img {
					transform: rotate(-90deg);
				}
				.more {
					display: none;
				}
			}
		}
	}
	.scroll-hidden {
		@include hideScroll;
	}
}

@media only screen and (max-width: 768px) {
  .center-text-on-portable {
		text-align: center;
  }
}

.ui.styled.accordion.list-filters {
	.ui.header { 
		font-size:1em;
		font-weight:600;
		margin-bottom:.5rem;
	}
	.ui.segment {
		> .ui.input {
			max-width:100%;
		}
	}
	.ui.horizontal.segments {
		margin-top:.5rem;
	}
	> .active.title {
		padding-bottom:0;
	}

	.sub.header {
		font-size: 0.875rem;
	  }

}

.hide-empty-row {
  &:has(.column:empty) {
    display: none;
  }
}