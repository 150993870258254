#main-menu {
	position:sticky;
	&.extra {
		position:relative;

		body.icosm & {
			@include md {
				position: sticky;
			}
		}

		body:not(.icosm) & {
			@include lg {
				position: relative;
			}
		}

		.ui.simple.dropdown > .menu:not(.admin-menu) {
			overflow-y: scroll;
			max-height: 80vh;
			@include hideScroll;
		}
	}
	top:0;
	background:white !important;
	padding:0.759em 0;
	z-index:900;
	transition:.2s ease-in box-shadow;
	&.scroll,
	&.active {
		box-shadow:0px 2px 3px 0px rgba(80,80,80,.1);
	}
	
	.logo {
		width: 200px;
		height: auto;
	}
	@media only screen and (max-width: 360px) {
		width: 100vw;
		.logo {
			width: 45vw;
			margin-right: 1rem;
		}
	}
	@media only screen and (max-width: 280px) {
		.sign-in {
			padding: 0.75rem 1rem!important;
		}
	}
	i.sidebar {
		margin-right:0;
	}
	
	> .container > .menu {
		background:transparent !important;
		border:none !important;
		border-radius:0 !important;
		margin-bottom:0 !important;
		box-shadow: none !important;
		display:flex;
		> * {
			@include md {
				padding-top:0 !important;
				padding-bottom:0 !important;
			}
		}

		.get-started {
			max-height: 36px;
		}
		
		/*&.bottom {
			margin-top:0;
		}*/
		
		> * {
			// display:flex;
			&.right {
				justify-content: flex-end;
			}
		}
		
		> .item {
			align-items: center;
			display:flex;
			&:first-child,
			&:nth-child(2) {
				padding-left:.5em;
				margin-left:-.5em;
			}
			&:first-child {
				padding-right:.5em;
			}
			
			&.burger {
				padding-left:0.5em;
				padding-right:0.5em;
			}
		}
		
	}
	
	.menu-items-wrap {
		flex:1 1 auto;
		flex-wrap: nowrap;
		min-width:0;
		&.bottom {
			display:none;
		}
		&.top {
			justify-content:flex-end;
		}
	}

	/*&.switch, &.admin {
		.menu-items-wrap.top {
			overflow: hidden;
			> .menu.menu-items > :not(.mega-menu) {
				display: none;
				pointer-events: none;
			}
		}
		.menu-items-wrap.bottom {
			display:flex;
		}
	}
	&.admin {
		.ui.menu,
		.menu-items-wrap {
			animation: fadein .3s;
		}
	}*/
	
	.menu.menu-items {
		
		align-items: center;
		display:flex !important;
		flex-wrap: nowrap;
		@include sm {
			margin:0 (1.14285714em / -2);
		}	
		
		/*&.bottom {
			flex:1 1 auto;
			justify-content:space-between;
			flex-wrap:wrap;
		}*/
	}
	
	.item:not(.pagination *) {
		padding-left:(1.14285714em / 1.5);
		padding-right:(1.14285714em / 1.5);
		@include sm {
			padding:(0.92857143em / 2) (1.14285714em / 2);
		}
		&:before {
			content: none;
		}
		&.right {
			margin-left: unset !important;
		}
		> span.new {
			background-color: #222;
			color:white;
			padding:.2em .5em;
			border-radius:2px;
			display:inline-block;
			margin-left:.3em;
			line-height: 1em;
			font-size:.9em;
		}
		&.md {
			@include sm {
				display:none;
			}
		}
		&.sm {
			@include md {
				display:hidden;
			}
		}
	}
	
	.user-menu {
		> .item.dropdown {
			@include md {
				padding:calc(0.92857143em - 4px) .5em;
			}
		}
		.menu {
			> .header {
				text-transform:none;
				font-size:.9rem;
				line-height: 1.2em;
			}
			.item {
				padding: .75rem 1rem;
				line-height: 1.25rem;
			}
			.divider {
				margin-bottom: 0;
			}

		}
	}
	
	.user-area {
		> img {
			border-radius:3px;
		}
		.name {
			@include sm {
				display:none;
			}
		}
	
	}
	
	.admin-menu {
		font-size:.9em;
		width: auto;
		overflow: visible;
		>.item {
			padding-top: 0.655714em !important;
			padding-bottom: 0.655714em !important;
			display: flex;
			gap: 0.75em;
			.icon {
				margin-right: 0 !important;
			}

			@media screen and (max-width:425px) {
				> div {
					width: 3em;
					overflow: hidden;
					text-overflow: ellipsis;
				}	
			}
		}

		a.item {
			font-size:.8em !important;
			padding:0.35em !important;
			.icon {
				width: 2em;
			}

			@media screen and (max-width:518px) {
				max-width: 20em;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			@media screen and (max-width:320px) {
				max-width: 14em;
			}
		}

		> div {
			padding:.5em .2em;
			> a.item {
				font-size:1em !important;
				line-height: 1.5em;
				font-weight: normal;
			}
		}

		> :first-child {
			margin-top:0;
		}
		.header {
			page-break-inside: avoid;
			&::after {
			    content: "";
			    display: block;
			    height: 100px;
			    margin-bottom: -100px;
			}
		}

		.sub-sub-header {
			padding-top: .5em;
			padding-bottom: .5em;
		}
	}
	
	.cta_hl {
		background-color:#4CFF7C22;
	}
	
	@media screen and (max-width:320px) {
		>.container {
			margin-left: .5em !important;
			margin-right: .5em !important;
		}
		/*&.switch {
			.menu.menu-items {
				margin:0 -.5em;
				.item {
					padding:0.2em  0.3em;
				}
			}
		}*/
	}
	
}

@mixin font($size, $lh) {
	font-size: $size;
	line-height: $lh;
}
.iscr #main-menu {
	background-color: #fff;

	@include sm {
		.logo {
			width: 168px;
		}
	}

	.navigator {
		@include sm {
			.logo {
				width: 144px;
			}
			.user-menu .ui.button {
				padding: .5rem!important;
			}
			.burger {
				padding-left: .25rem!important;
				> div {
					height: 34px;
					width:34px;
				}
			}
		}
	}
    @media (max-width: 440px) {
		position: relative;
		&.no-user {
			.navigator {
				flex-wrap: wrap;
				.burger {
					position: absolute;
					top: 0;
					right: 1rem;
					padding-left: .3rem;
				}
				> .user-menu {
					width: 100%;
					.ui.button {
						margin-top: .5rem;
						width: calc(50% - .5rem);
						padding: .25rem!important;
					}
				}
			}
		}

		&.user-in {
			.user-menu {
				padding-bottom: 28px;
				.ui.button {
					padding: .25rem!important;
				}
			}
			.ui.container > .menu {
				align-items: start;
			}
			#get-started {
				position: absolute;
				left: 1rem;
				right: 1rem;
				top: 70px;
				height: fit-content;
			}
			.burger {
				padding-top: 0;
			}
		}
	}

	/*&.extra {
		.mega-menu .panel {
			transform: translate3d(0px, 160px, 0px)!important;
			@include md {
				transform: translate3d(0px, 80px, 0px)!important;
			}
		}
	}*/
	.menu-items-wrap {
		margin-right: 1rem;
	}
	.mega-menu {
		i.sidebar { color:$isBrandColor;}
		.item {
			> span.new {
				background-color: $isBrandColor;
			}
		}
		.image-link:hover p {
			color: $isDark;
		}
		.list { 
			a {
				line-height: 1.8rem;
				margin-bottom: 8px;
				padding: 0;
			}
			.info-link::after {
				@include md {
					height: 1.8rem;
				}
			}
			
		}
		.button:focus { outline: none; }
		a, a > div {
			@include hover_transition(.1s);
		}
		.section-side, .section-main {
			animation: fadein 1s;
		}
		.side-menu {
			top: 72px!important;
		}
		.panel {
			overflow-y: scroll;
			max-height: 100vh;
			@include transform(translate3d(0px, 46px, 0px)!important);
			padding-bottom: 10vh;
			@include xl {
				height: auto;
			}
			@include xl {
				padding-bottom: 0;
			}
			
			// transform: translate3d(0px, 120px, 0px)!important;
			@include hideScroll;

		}
		.tiles {
			.tile {
				.icon {
					padding-right: 0;
				}
				&:hover {
					color: $isDark!important;
					svg {
						color: $isBrandColor;
					}
				}
			}
		}
    }
	.item {
		// padding-top: 0.44rem;
		// padding-bottom: 0.44rem;
		font-weight: 600;
		font-size: .875rem;
		margin: 0;
		&:hover {
			background-color: transparent;
			color: $isBrandColor;
			margin: 0;
			.dropdown.icon {
				color: $isBrandColor;
				&:before {
					transform: rotate(180deg);
					top: 0;
				}
			}
		}
	}
	&.user {
		padding-bottom: .759em;
	}
	&:not(.user) > .menu {
		height: 72px;
	}
	.ui.container {
		max-width: 1440px!important;
		width: auto;
		margin: 0 auto!important;

		> .menu {
			align-items: center;
			.item.burger {
				padding-left: 1rem;
				padding-right: 0;
				@include sm {
					padding-left: .5rem;
				}
			}
			
		}
		@include sm {
			padding: 0 1rem;
		}
		@include md{
			padding: 0 1.25rem;
		}
	}
}

.info-link {
	@include arrow_link;
}
