$import-google-fonts: false !default;
$font-url: 'https://fonts.googleapis.com/css?family=Lato:400,700,400italic,700italic&subset=latin,latin-ext' !default;

@mixin font-family($n, $w) {
    font-family: 'gotham-rounded';
    font-style: normal;
    font-weight: $w;
    src: url('./fonts/woff2/GothamRnd-#{$n}_Web.woff2') format('woff2'),
    url('./fonts/woff/GothamRnd-#{$n}_Web.woff') format('woff');
    font-display: swap;
}

@font-face {
    @include font-family('Book', 400);
}

@font-face {
    @include font-family('Medium', 500);
}

@font-face {
    @include font-family('Medium', 700);
    //@include font-family('Bold', 700);
}

$font-name: 'gotham-rounded' !default;

$font-family: $font-name, 'Helvetica Neue', Arial, Helvetica, sans-serif !default;

$use-custom-scrollbars: true !default;
$icons-font-path: './icons' !default;
$flags-image-path: './images' !default;

$bold: 600;
$normal: normal;


/*---  Semantic UI  ---*/
$textColor:rgba(0, 0, 0, 0.87);

/*---  Colors  ---*/
$red              : #DB2828;
$orange           : #F2711C;
$yellow           : #FBBD08;
$olive            : #B5CC18;
$green            : #00873F;
$teal             : #00B5AD;
$blue             : #0065F2;
$violet           : #6435C9;
$purple           : #A333C8;
$pink             : #E03997;
$brown            : #A5673F;
$grey             : #767676;
$black            : #1B1C1D;

$fullBlack        : #000000;
$offWhite         : #F9FAFB;
$darkWhite        : #F3F4F5;
$midWhite         : #DCDDDE;
$white            : #FFFFFF;

/*--- Colored Text ---*/
$redTextColor    : $red;
$orangeTextColor : $orange;
$yellowTextColor : #B58105; // Yellow text is difficult to read
$oliveTextColor  : #8ABC1E; // Olive is difficult to read
$greenTextColor  : #1EBC30; // Green is difficult to read
$tealTextColor   : #10A3A3; // Teal text is difficult to read
$blueTextColor   : $blue;
$violetTextColor : $violet;
$purpleTextColor : $purple;
$pinkTextColor   : $pink;
$brownTextColor  : $brown;

/*---  Light Colors  ---*/
$lightRed         : #FF695E;
$lightOrange      : #FF851B;
$lightYellow      : #FFE21F;
$lightOlive       : #D9E778;
$lightGreen       : #2ECC40;
$lightTeal        : #6DFFFF;
$lightBlue        : #54C8FF;
$lightViolet      : #A291FB;
$lightPurple      : #DC73FF;
$lightPink        : #FF8EDF;
$lightBrown       : #D67C1C;
$lightGrey        : #DCDDDE;
$lightBlack       : #545454;


$redHover                 : saturate(darken($red, 5), 10%);
$orangeHover              : saturate(darken($orange, 5), 10%);
$yellowHover              : saturate(darken($yellow, 5), 10%);
$oliveHover               : saturate(darken($olive, 5), 10%);
$greenHover               : saturate(darken($green, 5), 10%);
$tealHover                : saturate(darken($teal, 5), 10%);
$blueHover                : saturate(darken($blue, 5), 10%);
$violetHover              : saturate(darken($violet, 5), 10%);
$purpleHover              : saturate(darken($purple, 5), 10%);
$pinkHover                : saturate(darken($pink, 5), 10%);
$brownHover               : saturate(darken($brown, 5), 10%);
$whiteHover               : saturate(darken($white, 5), 10%);

$lightRedHover            : saturate(darken($lightRed, 5), 10%);
$lightOrangeHover         : saturate(darken($lightOrange, 5), 10%);
$lightYellowHover         : saturate(darken($lightYellow, 5), 10%);
$lightOliveHover          : saturate(darken($lightOlive, 5), 10%);
$lightGreenHover          : saturate(darken($lightGreen, 5), 10%);
$lightTealHover           : saturate(darken($lightTeal, 5), 10%);
$lightBlueHover           : saturate(darken($lightBlue, 5), 10%);
$lightVioletHover         : saturate(darken($lightViolet, 5), 10%);
$lightPurpleHover         : saturate(darken($lightPurple, 5), 10%);
$lightPinkHover           : saturate(darken($lightPink, 5), 10%);
$lightBrownHover          : saturate(darken($lightBrown, 5), 10%);
$lightGreyHover           : saturate(darken($lightGrey, 5), 10%);
$lightBlackHover          : saturate(darken($fullBlack, 5), 10%);
$fullBlackHover           : lighten($fullBlack, 5%);
$blackHover               : lighten($black, 5%);
$greyHover                : lighten($grey, 5%);

/*--- Colored Backgrounds ---*/
$redBackground    : #FFE8E6;
$orangeBackground : #FFEDDE;
$yellowBackground : #FFF8DB;
$oliveBackground  : #FBFDEF;
$greenBackground  : #E5F9E7;
$tealBackground   : #E1F7F7;
$blueBackground   : #DFF0FF;
$violetBackground : #EAE7FF;
$purpleBackground : #F6E7FF;
$pinkBackground   : #FFE3FB;
$brownBackground  : #F1E2D3;

/*--- Colored Headers ---*/
$redHeaderColor    : darken($redTextColor, 5%);
$oliveHeaderColor  : darken($oliveTextColor, 5%);
$greenHeaderColor  : darken($greenTextColor, 5%);
$yellowHeaderColor : darken($yellowTextColor, 5%);
$blueHeaderColor   : darken($blueTextColor, 5%);
$tealHeaderColor   : darken($tealTextColor, 5%);
$pinkHeaderColor   : darken($pinkTextColor, 5%);
$violetHeaderColor : darken($violetTextColor, 5%);
$purpleHeaderColor : darken($purpleTextColor, 5%);
$orangeHeaderColor : darken($orangeTextColor, 5%);
$brownHeaderColor  : darken($brownTextColor, 5%);

$inputBorderRadius: 0.375rem;
$inputHeight: 2.7rem;

@mixin inputField() {
    font-family: $font-family;
    color: #272727;
    font-size: 1rem;
    font-weight: normal;
    padding: 0.563rem 0.813rem;
    line-height: 1.5rem;
    text-align: left;
    outline: none;
    -webkit-appearance: none;
    tap-highlight-color: rgba(255, 255, 255, 0);
    margin: 0em;
    background: #FFFFFF;
    border: 1px solid #D1D5DB;
    border-radius: $inputBorderRadius;
    max-width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
        flex: 1 0 auto;
}

@mixin borderTransition(){
    -webkit-box-shadow: 0em 0em 0em 0em transparent inset;
            box-shadow: 0em 0em 0em 0em transparent inset;
    -webkit-transition: color 0.1s ease, border-color 0.1s ease, box-shadow 0.1s ease;
    transition: color 0.1s ease, border-color 0.1s ease, box-shadow 0.1s ease;
}
